.react-datepicker-wrapper {
    /* height: 48px; */
    background: #FFF;
    width: 100%;
}

.custom-datepicker-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.custom-datepicker-input .icon svg {
    width: 28px;
    height: 28px;
}

.custom-datepicker-input input {
    border: none;
    height: 48px;
    width: 80%;
}

.picker-label {
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: -0.408px;
    margin-bottom: 10px;
}

.picker-div{
    display: flex;
    flex-direction: column;
}
.react-datepicker {
    width: 100%; 
    width: 409px;
    font-size: 1rem;
    bottom: 11px; 
  }
  .react-datepicker__triangle{
    display: none;
  }
  
  
  .react-datepicker__month-container {
    width: 100%; 
  }
  
  
  .react-datepicker__day, .react-datepicker__day-name {
    width: 3.3em; 
    line-height: 2.5em; 
    margin: 0.166em; 
    height: 1.9em;
  }
  .react-datepicker__day-name{
    width: 3.1rem;
    opacity: 0.5; 
  }
  .react-datepicker__day{
    line-height: 1em;
  }
  
  /* Adjust the header size */
  .react-datepicker__header {
    font-size: 1.1em; /* Larger text in the header */
    padding: 0.5em 0; /* More or less padding in the header */
  }
  
  /* Adjust navigation arrows size if necessary */
  .react-datepicker__navigation {
    top: 1em; /* Position from the top */
    width: 1em; /* Width of arrows */
    height: 1em; /* Height of arrows */
  }


  .react-datepicker__month-select {
    width: 149px; /* or any other value */
    height: 32px; /* or any other value */
    margin-top: 10px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    font-family: Inter;
    background-color: aliceblue;
    border: 1px solid #BCBCBC ;
}
.react-datepicker__year-select {
    background-color: aliceblue;
    width: 149px; 
    height: 32px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    font-family: Inter;
    border: 1px solid #BCBCBC ;
  }


  /* arrow removed */
  .react-datepicker__month-select,
  .react-datepicker__year-select {
    -webkit-appearance: none;  
    -moz-appearance: none;     
    appearance: none;          
    background-image: none;
  }
  .react-datepicker__month-select::-ms-expand,
  .react-datepicker__year-select::-ms-expand {
    display: none;
  }

  .react-datepicker__navigation-icon::before {
    border-color: black;
  }


  .react-datepicker__current-month {
    font-family: Inter;
    font-size: 20px;
    font-weight: 500; 
  }

  .react-datepicker__day {
    
    line-height: 2em; 
   
  }
  .react-datepicker__day--selected {
    background-color:  #D9D9D9;
    color: black
; 
  }
  .react-datepicker__day--selected:hover {
    background-color: #D9D9D9; 
  }

  .react-datepicker__day--keyboard-selected {
    background-color:  #D9D9D9;
    color: black
  }

  .react-datepicker__input-container input {
    background-image: url('../assets/calender.svg'); 
    background-position: left 10px center; 
    background-repeat: no-repeat;
    padding-left: 60px; 
    cursor: pointer;
    border: none;
  }
  .react-datepicker__input-container input:focus {
    outline: none; 
    /* border: 1px solid #yourBorderColor; */
  }
  .react-datepicker__day--outside-month {
    opacity: 0.5; /* Dim dates not in the current month */
  }

  
  @media (max-width: 601px) {
    .picker-label {
      font-size: 12px;
    }

    .react-datepicker {
      width: 335px;
      font-size: 0.9rem;
    }

    .react-datepicker__day, .react-datepicker__day-name {
      width: 2.8em; 
      height: 1.5em;
    }

    .react-datepicker__day-name{
      width: 2.6rem;
      opacity: 0.5; 
    }

    .react-datepicker__header {
      font-size: 0.9em; /* Larger text in the header */
      padding: 1.2em 0; /* More or less padding in the header */
    }

    .react-datepicker__navigation {
      top: 0.9em; /* Position from the top */
      width: 0.9em; /* Width of arrows */
      height: 0.9em; /* Height of arrows */
    }

    .react-datepicker__month-select {
      width: 119px; /* or any other value */
      height: 28px; /* or any other value */
    }
    .react-datepicker__year-select {
      width: 119px; 
      height: 28px;
    }

    .react-datepicker__current-month {
      font-size: 16px;
    }
  }