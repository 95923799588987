.error-tooltip-box {
    color: black;
}
.error-tooltip-box .tooltip-inner-box::before{
    content: "";
    position: absolute;
    background: white;
    width: 20px;
    height: 20px;
    border-color: #808080 #808080 #fff #fff;
    border-width: 1px;
    border-style: solid;
    border-radius: 0 4px 0 0;
}
.error-tooltip-box.tooltip-container-bottom-left .tooltip-inner-box::before {
    top: -31px;
    left: 10px;
    rotate: -45deg;
}

.error-tooltip-box.tooltip-container-top-left .tooltip-inner-box::before {
    bottom: -31px;
    left: 10px;
    rotate: 135deg;
}

@media (max-width: 530px) {
    .error-tooltip-box {
       max-width: 360px;
    }

    .error-tooltip-box.tooltip-container-top-left {
        top: -95px !important;
    }
}

@media (max-width: 430px) {
    .error-tooltip-box {
       max-width: 285px;
    }

    .error-tooltip-box.tooltip-container-top-left {
        top: -95px !important;
    }
}