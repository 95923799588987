/* styles.css */

.custom-label {
    color: #FFF;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: -0.408px;
    margin-bottom: 10px;
  }
  
  .custom-form-control {
    width: 100%;
    margin-left: 0;
  }
  
