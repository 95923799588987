
.arrow {
    font-size: 20px;
    left: 0 !important;
    color: #fff;
}

.arrow::before {
    background-color: #fff;
}

.field-lable {
    color: #FFF;
    font-family: Inter !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: -0.408px;
    margin-bottom: 10px;
}

.input-field {
    font-size: 16px;
    display: flex !important;
    background-color: #fff;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    font-family: "Inter" !important;
    height: 48px;
    outline: none;
    font-family: Inter !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    letter-spacing: -0.408px !important;
}

.error-icon {
    color: #ff2A2A !important;
    width: 24px !important;
    height: 24px !important;
    padding-right: 5px !important;
}
.error-icon00 {
    color: #ff2A2A !important;
    width: 24px !important;
    height: 24px !important;
    padding-right: 5px !important;
}

@media (max-width: 920px) {
    .input-field {
        width: auto;
    }
}
@media (max-width: 400px) {
    .error-icon00 {
    margin-left: -19px !important;
}
}