body {
    font-family: 'Inter', sans-serif !important;
}

.MuiTypography-root {
    font-family: 'Inter', sans-serif !important;
}

.btn-yallow {
    background: #F4CD46;
}

.btn-white {
    background: #FFFFFF;
}

iframe {
    display: none !important;
}

#childrenElement::-webkit-scrollbar {
    display: none;
}

#childrenElement {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
}

.custom-calendar.rmdp-wrapper {
    background: #212226;
    font-family: 'Inter', sans-serif;
}

.custom-calendar.rmdp-wrapper .rmdp-range {
    background-color: #F4CD46;
    box-shadow: none;
    border-radius: 5px;
}

.custom-calendar.rmdp-wrapper .rmdp-day.rmdp-today span {
    background: #F4CD46;
    border-radius: 5px;
}

.custom-calendar.rmdp-wrapper .rmdp-week-day {
    font-size: 12px;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 0.6;
}

.custom-calendar.rmdp-wrapper .rmdp-day,
.custom-calendar.rmdp-wrapper .rmdp-week-day {
    font-size: 15px;
    font-weight: 400;
    color: #FFFFFF;
}

.custom-calendar.rmdp-wrapper .rmdp-header-values {
    font-size: 20px;
    font-weight: 500;
    color: #FFFFFF;
}

.custom-calendar.rmdp-wrapper .rmdp-border-bottom,
.custom-calendar.rmdp-wrapper .rmdp-border-top,
.custom-calendar.rmdp-wrapper .rmdp-border-left {
    border: none;
}

.custom-calendar.rmdp-wrapper .rmdp-day.rmdp-deactive,
.custom-calendar.rmdp-wrapper .rmdp-day.rmdp-disabled {
    opacity: 0.4;
}

.rmdp-container .rmdp-input {
    width: 314px;
    height: 45px;
    background: #212226;
    color: #fff;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #fff;
    border-radius: 0;
    padding-left: 50px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 500;
}

.rmdp-container .icon {
    left: 8px;
    width: 26px;
    height: 26px;
}

.rmdp-mobile .rmdp-action-button {
    width: 220px;
    height: 56px;
    border-radius: 53px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    font-family: Inter;
    text-transform: capitalize;
}

.rmdp-mobile .rmdp-action-buttons :nth-child(1) {
    border: 1px solid #fff;
}

.rmdp-mobile .rmdp-action-buttons :nth-child(1):hover {
    background: #fff;
    color: #000;
}

.rmdp-mobile .rmdp-action-buttons :nth-child(2) {
    border: 1px solid #F4CD46;
}

.rmdp-mobile .rmdp-action-buttons :nth-child(2):hover {
    background: #F4CD46;
    color: #000;
}

@media (max-width: 750px) {

    .custom-calendar.rmdp-mobile .rmdp-day,
    .rmdp-mobile .rmdp-week-day {
        width: 30px;
        height: 30px;
    }

    .custom-calendar.rmdp-wrapper .rmdp-day,
    .custom-calendar.rmdp-wrapper .rmdp-week-day {
        font-size: 13px;
    }

    .custom-calendar.rmdp-mobile .rmdp-day-picker {
        margin: 0;
    }
}

@media (max-width: 620px) {
    .rmdp-calendar-container-mobile .rmdp-mobile.rmdp-wrapper {
        width: 100%;
    }

    .custom-calendar.rmdp-mobile .rmdp-top-class {
        flex-direction: column;
    }

    .custom-calendar.rmdp-mobile .rmdp-day-picker {
        justify-content: space-evenly;
    }
}

@media (max-width: 450px) {

    .custom-calendar.rmdp-mobile .rmdp-day,
    .rmdp-mobile .rmdp-week-day {
        width: 25px;
        height: 25px;
    }

    .custom-calendar.rmdp-wrapper .rmdp-day,
    .custom-calendar.rmdp-wrapper .rmdp-week-day {
        font-size: 12px;
    }

    .custom-calendar.rmdp-mobile .rmdp-day-picker {
        margin: 0;
    }

    .custom-calendar.rmdp-mobile .rmdp-day span {
        font-size: 13px;
    }

    .custom-calendar.rmdp-wrapper .rmdp-header-values {
        font-size: 16px;
    }
    .rmdp-container .rmdp-input {
        width: 275px;
    }
}