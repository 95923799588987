#customisable-business-profile {
    max-width: 100%;
    font-size: clamp(10px, 16px, 2vw);
    padding: 0 8vw;
}

#customisable-business-profile .textfield-lable p {
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 1em;
    font-style: normal;
    font-weight: 300;
    /* line-height: 22px; */
    letter-spacing: -0.408px;
    margin-bottom: 6px;
    
}

#customisable-business-profile .profile-headding {
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 2em; 
    white-space: nowrap;
    /* font-style: normal; */
    font-weight: 700;
    /* line-height: 22px; */
    letter-spacing: -0.408px;
}


#customisable-business-profile .main-container {
   margin-top: 40px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

#customisable-business-profile .main-container .img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    flex-basis: 324px;
    padding: 0;
}

#customisable-business-profile .form-grid{
    max-width: 886px;
}

#customisable-business-profile .form-grid > div{
    margin-top: -8px;
}

#customisable-business-profile .main-container .image-upload-background {
    flex-shrink: 0;
    border-radius: 300px;
    background: #EFEFEF;
    margin-left: 30px;
    margin-right: 30px;
}

#customisable-business-profile .main-container .image-upload-background .image-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px
}

#customisable-business-profile .main-container .image-upload-background .image-upload .profile-image {
    /* flex-shrink: 0;
    border-radius: 300px;
    width: 300px;
    height: 300px; */
}
/* Styles for mobile devices */
@media (max-width: 775px) {
    #customisable-business-profile .main-container .image-upload-background .image-upload .profile-image {
        /* width: 150px !important; */
        /* height: 150px !important; */
        /* margin-top: 15px; */
        /* border-radius: 150px !important;  */
    }
    #customisable-business-profile .main-container .image-upload-background {
        /* width: 150px !important; */
        /* height: 150px !important; */
        /* border-radius: 150px !important;  */

}
 .image-priview{
        /* width: 150px !important; */
        /* height: 150px !important; */
        /* border-radius: 150px !important;  */

}
}

#customisable-business-profile .image-upload {
    width: clamp(150px, 21vw, 300px);
    height: clamp(150px, 21vw, 300px);
    text-align: center;
}

#customisable-business-profile .main-container .image-upload-background .image-upload .upload-profile-photo {
    color: #000;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 1em;
    /* font-style: normal; */
    font-weight: 300;
    /* line-height: 30px; */
    letter-spacing: -0.408px;
}

#customisable-business-profile .main-container .textfield-container {
    display: flex;
    gap:4%;
}

#customisable-business-profile .main-container .email-field {
    margin-bottom: 20px;
    display: flex;
    background-color: #fff;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    height: 48px;
    width: auto !important;
}

#customisable-business-profile .main-container .textfield-gender svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    color: #000;
    margin-right: 15px;
}

#customisable-business-profile .button-box {
    display: flex;
    justify-content: flex-end;
    margin: 2% 0 0 0;
    gap: 20px;
}

#customisable-business-profile .button-box .button {
    display: flex;
    width: 30%;
    height: 48px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    border-radius: 70px;
    opacity: 0.5;
    background: #F4CD46;
    text-transform: capitalize;
    opacity: 0.5;
}

#customisable-business-profile .button-box button {
    width: 225px;
    height: 56px;
    border-radius: 70px;
    text-transform: none;
    font-weight: 600;
    font-size: 16px;
}

#customisable-business-profile .button-box .btn-opacity{
    display: flex;  width: 30%;
    height: 48px;  justify-content: center;
    align-items: center;  gap: 12px;  flex-shrink: 0;
    border-radius: 70px; background: #F4CD46; text-transform: capitalize;
    color: #000 !important; font-weight: bold !important;
}

#customisable-business-profile .button-box .button .button-text {
    color: #000 !important;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 1em;
    font-style: normal;
    font-weight: 600;
    /* line-height: 22px; */
    letter-spacing: -0.408px;
}

#customisable-business-profile .button-box .btn-opacity .button-text {
    color: #000 !important; text-align: center; font-family: 'Inter', sans-serif;
    font-size: 1em; font-weight: 600; letter-spacing: -0.408px;
}

/* -----------------------Subscribers List Component------------------------- */
/* #subscribers-list-container-main .subscriber-list .subscribers-table {
    width: 80%;

    & .subscribers-table-head {
        border-radius: 3px;
    }

    & .subscribers-table-body {

        & .view-subscriber-details-btn {
            position:absolute;
            top: -5px;
            left: 26px;
            display: none;
        }

        & .view-subscriber-details-btn::before {
            content: "";
            width: 20px;
            height: 20px;
            transform: rotate(45deg);
            background-color: #393939;
            position:absolute;
            top: 7px;
            left: -5px;
        }

        & .moreVertIconBox:hover .view-subscriber-details-btn{
            display: block;
        }
    }

} */
#subscribers-list-container-main .subscriber-list .view-subscriber-details-btn {
    display: none;
}
#subscribers-list-container-main .subscriber-list .view-subscriber-details-btn::before {
    content: "";
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    background-color: #393939;
    position:absolute;
    top: 7px;
    left: -5px;
}

#subscribers-list-container-main .subscriber-list .moreVertIconBox:hover .view-subscriber-details-btn{
    display: block;
    background-color: #393939;
}


@media (max-width: 1246px) {
    #customisable-business-profile .main-container {
        justify-content: center;
    }

    #customisable-business-profile .profile-headding {
        margin-left: 45px;
    }
}

@media (max-width: 920px) {
    #customisable-business-profile .main-container .form-grid {
        padding: 0 15px;
    }

    #customisable-business-profile .main-container .textfield-container {
        /* flex-direction: column;
        align-items: center; */
        width: 100%;
    }

    #customisable-business-profile .main-container .textfield-container > div {
        /* width: 100%; */
        max-width: 100%;
        margin: 0;
        margin-bottom: 6px;
    }
}

@media (max-width: 866px) {
    #customisable-business-profile .main-container .textfield-container {
        flex-direction: column;
        align-items: center;
        margin-bottom: 15px;
        flex-basis: 100%;
    }
}

@media (max-width: 950px) {
    #subscribers-list-container-main .subscriber-list .view-subscriber-details-btn::before {
        content: "";
        width: 20px;
        height: 20px;
        transform: rotate(45deg);
        background-color: #393939;
        position:absolute;
        top: 7px;
        left: unset;
        right: -5px;
    }
}

@media(max-width: 601px) {
    #customisable-business-profile {
        padding: 0 12px;
    }

    #customisable-business-profile .profile-headding {
        margin-top: 20px;
        margin-left: 10px;
    }

     #customisable-business-profile .buttons {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    #customisable-business-profile .button-box {
        justify-content: center;
        width: 100%;
    }

    #customisable-business-profile .button-box button {
        width: 60%;
        font-size: 14px;
        /* margin-top: 30px; */
    }

    .button-box {
        justify-content: center;
        width: 100%;
    }
}

/* Reporting web start */

.reporting_wrapper{
    color:#fff;
    padding: 20px 10%;
}


.report_button_wrapper .radio {
  /* border-radius: 15px; */
  display: inline-flex;
  overflow: hidden;
  margin: 20px 0px;
  padding: 5px;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.report_button_wrapper .radio__input {
  display: none;
}

.report_button_wrapper .radio__label {
    padding: 15px 14px;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    color: #fff;
    background: #000000;
    cursor: pointer;
    transition: background 0.1s;
    border: 1px solid #fff;
    border-radius: 63px;
    width: 225px;
    height: 56px;
    font-weight: 600;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.report_button_wrapper .radio__input:checked + .radio__label {
    color: #000000;
    background: #fff;
    border-radius: 63px;
}

.download_button{
        display: flex;
        align-items: center;
        background: black;
        color: #fff;
        padding: 14 27;
        border: 1px solid #F4CD46;
        border-radius: 56px;
        font-weight:600;
        font-size: 16px;
}

@media(max-width: 650px) {
    .report_button_wrapper .radio__label {
        width: 200px;
        height: 50px;
        padding: 10px;
        font-size: 15px;
    }
}


/* Reporting web end */
